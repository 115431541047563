<template>

  <div>
    <b-card
      :header-html="header"
      :sub-title="`${((ErrorMsgId !== '' && ErrorMsgId !== null) || (InQueue !== '' && InQueue !== null)) ? '' : `MOT: ${MOT}` }`"
    >
      <b-button-toolbar justify>
        <div>
          <b-button
            v-if="activeUserInfo.userRole !== 'readonly admin' && activeUserInfo.userRole !== 'read'"
            v-b-tooltip.hover="'Request Update'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="RequestUpdate()"
          >
            <span class="align-middle">Request Update</span></b-button>&nbsp;
          <b-button
            v-if="(activeUserInfo.userRole !== 'readonly admin' && activeUserInfo.userRole !== 'read' && subscriptionstatus !== 'true') && (ErrorMsgId === '' || ErrorMsgId === null) && (InQueue === '' || InQueue === null)"
            v-b-tooltip.hover="'Subscribe'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mb-1"
            @click="Subscribe()"
          >
            <font-awesome-icon
              :icon="['fas', 'rss']"
              size="lg"
            />
          </b-button>&nbsp;
          <b-button
            v-if="(ErrorMsgId === '' || ErrorMsgId === null) && (InQueue === '' || InQueue === null)"
            v-b-tooltip.hover="'Print'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mb-1"
            @click="Print()"
          >
            <font-awesome-icon
              :icon="['fas', 'print']"
              size="lg"
            />
          </b-button>&nbsp;
          <b-button
            v-b-tooltip.hover="'Refresh'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mb-1"
            @click="LoadAll()"
          >
            <font-awesome-icon
              :icon="['fas', 'sync']"
              size="lg"
            />
          </b-button>
        </div>
        <div>
          <label>{{ 'Queried On: ' + QueryDate }}</label><br>
          <label>{{ 'Last Updated: ' + LastUpdated }}</label>
          <!-- <b-button
            v-b-tooltip.hover="'Refresh'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mb-1"
            @click="Refresh()"
          >
            <font-awesome-icon
              :icon="['fas', 'sync']"
              size="lg"
            />
          </b-button>&nbsp;
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="mb-1"
            @click="clearFilter()"
          ><font-awesome-icon
             :icon="['fas', 'filter']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Clear</span></b-button> -->
        </div>
      </b-button-toolbar>
      <div v-if="InQueue !== '' && InQueue !== null">
        <b-row>
          <b-col md="12">
            <label><b><i>Status:</i></b></label>
            <b-form-input
              v-model="InQueue"
              v-uppercase
              readonly
            />
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <b-row v-if="ErrorMsgId !== '' && ErrorMsgId !== null">
          <b-col md="6">
            <label><b><i>Error Code:</i></b></label>
            <b-form-input
              v-model="ErrorMsgId"
              v-uppercase
              readonly
            />
          </b-col>
          <b-col md="6">
            <label><b><i>Error Message:</i></b></label>
            <b-form-input
              v-model="ErrorMsg"
              v-uppercase
              readonly
            />
          </b-col>
        </b-row>
        <!-- Air Cargo Status -->
        <div
          v-if="(ErrorMsgId === '' || ErrorMsgId === null) && type == 'A'"
          class="mb-1"
        >
          <b-row>
            <!-- Air Cargo Status -->
            <b-col md="3">
              <label><b><i>FIRMS:</i></b></label>
              <b-form-input
                v-model="FIRMS"
                v-uppercase
                readonly
              />
            </b-col>
            <b-col md="3">
              <label><b><i>ETA:</i></b></label>
              <b-form-input
                v-model="DOA"
                v-uppercase
                readonly
              />
            </b-col>
            <b-col md="3">
              <label><b><i>Carrier:</i></b></label>
              <b-form-input
                v-uppercase
                :value="`${CarrierCodeIATA} (${CarrierCode})`"
                readonly
              />
            </b-col>
            <b-col md="3">
              <label><b><i>Flight #:</i></b></label>
              <b-form-input
                v-model="FlightVoyage"
                v-uppercase
                readonly
              />
            </b-col>
          </b-row>
        </div>

        <!-- ORT Cargo Status -->
        <div
          v-if="(ErrorMsgId === '' || ErrorMsgId === null) && type == 'O'"
          class="mb-1"
        >
          <b-row>
            <b-col md="3">
              <label><b><i>Conveyance/Vessel Name:</i></b></label>
              <b-form-input
                v-model="ConveyanceName"
                v-uppercase
                readonly
              />
            </b-col>
            <b-col md="3">
              <label><b><i>Voyage:</i></b></label>
              <b-form-input
                v-model="FlightVoyage"
                v-uppercase
                readonly
              />
            </b-col>
            <b-col md="3">
              <label><b><i>Vessel Departure Port:</i></b></label>
              <b-form-input
                v-model="VesselDepartPort"
                v-uppercase
                readonly
              />
            </b-col>
            <b-col md="3">
              <label><b><i>Vessel Departure Date:</i></b></label>
              <b-form-input
                v-model="VesselDepartDate"
                v-uppercase
                readonly
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <label><b><i>FIRMS:</i></b></label>
              <b-form-input
                v-model="FIRMS"
                v-uppercase
                readonly
              />
            </b-col>
            <b-col md="3">
              <label><b><i>ISF On File?:</i></b></label>
              <b-form-input
                v-uppercase
                :style="`${ ISFIndicator ==='Y' ? 'color:green' : 'color:red' }`"
                :value="`${ ISFIndicator ==='Y' ? 'Yes' : 'No' }`"
                readonly
              />
            </b-col>
            <b-col md="3">
              <label><b><i>ETA:</i></b></label>
              <b-form-input
                v-model="DOA"
                v-uppercase
                readonly
              />
            </b-col>
            <b-col md="3">
              <label><b><i>In-Bond #:</i></b></label>
              <b-form-input
                v-model="InBondNumber"
                v-uppercase
                readonly
              />
            </b-col>
          </b-row>
          <b-row>

            <b-col md="3">
              <label><b><i>MBL Qty:</i></b></label>
              <b-form-input
                v-model="ManifestQty"
                v-uppercase
                readonly
              />
            </b-col>
            <b-col md="3">
              <label><b><i>MBL Unit:</i></b></label>
              <b-form-input
                v-model="Unit"
                v-uppercase
                readonly
              />
            </b-col>
            <b-col md="3">
              <label><b><i>Total HBL Qty:</i></b></label>
              <b-form-input
                v-model="TotalHouseQty"
                v-uppercase
                readonly
              />
            </b-col>
            <b-col md="3">
              <label><b><i>HBL Unit:</i></b></label>
              <b-form-input
                v-model="TotalHouseUnit"
                v-uppercase
                readonly
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <label><b><i>Manifested POU:</i></b></label>
              <b-form-input
                v-model="ManifestedPOU"
                v-uppercase
                readonly
              />
            </b-col>
            <b-col md="3">
              <label><b><i>Actual POU:</i></b></label>
              <b-form-input
                v-model="ActualPOU"
                v-uppercase
                readonly
              />
            </b-col>
            <b-col md="3">
              <label><b><i>In-Bond Originating Port:</i></b></label>
              <b-form-input
                v-model="InBondOriginatingPort"
                v-uppercase
                readonly
              />
            </b-col>
            <b-col md="3">
              <label><b><i>In-Bond Dest. Port:</i></b></label>
              <b-form-input
                v-model="ManifestedInBondDestPort"
                v-uppercase
                readonly
              />
            </b-col>
          </b-row>
        </div>

        <AgGridNoEdits
          v-if="(ErrorMsgId === '' || ErrorMsgId === null)"
          ref="CSQDetailGrid"
          :grid="aggrid"
          :tblstyle="'height:450px'"
          @LoadAll="LoadAll"
        />
      </div>

    </b-card>
    <CargoStatusAirHouse
      v-if="(ErrorMsgId === '' || ErrorMsgId === null) && (InQueue === '' || InQueue === null) && type === 'A' && BillType !== 'Regular BOL'"
      :key="`AH${index}`"
      :queryid="queryid"
      :type="type"
    />
    <CargoStatusORTHouse
      v-if="(ErrorMsgId === '' || ErrorMsgId === null) && (InQueue === '' || InQueue === null) && type === 'O' && BillType !== 'Regular BOL'"
      :key="`OH${index}`"
      :queryid="queryid"
      :type="type"
    />
  </div>
</template>

<script>
import axios from '@axios'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import AgGridNoEdits from '@/layouts/components/AgGridNoEdits.vue'
import CargoStatusAirHouse from './CargoStatusAirHouse.vue'
import CargoStatusORTHouse from './CargoStatusORTHouse.vue'
// PDF Make

pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  components: {
    AgGridNoEdits,
    CargoStatusAirHouse,
    CargoStatusORTHouse,
  },
  data() {
    return {
      header: '',
      queryid: this.$route.params.queryid,
      type: this.$route.params.type,
      Master: this.$route.params.master,
      subscriptionstatus: this.$route.params.subscriptionstatus,
      index: 1,
      POE: '',
      SCAC: '',
      EntryFilerCode: '',
      EntryNumber: '',
      EntryType: '',
      Importer: '',
      BrokerRefNumber: '',
      CarrierCode: '',
      CarrierCodeIATA: '',
      ConveyanceName: '',
      FlightVoyage: '',
      DOA: '',
      TripNumber: '',
      FIRMS: '',

      BillType: '',
      BillTypeCode: '',
      ISFIndicator: '',
      InBondNumber: '',
      MOT: '',
      Unit: '',
      ManifestQty: 0,
      MBL: '',
      ManifestedPOU: '',
      ActualPOU: '',
      InBondOriginatingPort: '',
      ManifestedInBondDestPort: '',
      VesselDepartPort: '',
      VesselDepartDate: '',
      TotalHouseQty: 0,
      TotalHouseUnit: '',
      QueryDate: '',
      LastUpdated: '',
      QueryBy: '',
      aggrid: [],
      houseBills: [],
      masterBills: [],

      ErrorMsgId: '',
      ErrorMsg: '',

      InQueue: 'In Queue - Pending',
      TableStyleStripeLine: {
        hLineWidth(i, node) {
          if (i === 0 || i === node.table.body.length) {
            return 0
          }
          return (i === node.table.headerRows) ? 2 : 1
        },
        vLineWidth() { return 0 },
        hLineColor(i) { return i === 1 ? 'black' : '#aaa' },
        paddingLeft(i) { return i === 0 ? 0 : 8 },
        paddingRight(i, node) { return (i === node.table.widths.length - 1) ? 0 : 8 },
        fillColor(rowIndex) {
          return (rowIndex % 2 === 0) ? '#CCCCCC' : null
        },
      },
    }
  },
  computed: {
    isLoggedIn() { return this.$store.getters.user.isLoggedIn },
    activeUserInfo() {
      return this.$store.state.user.AppActiveUser
    },
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    // init
    this.aggrid = this.InitGridInstance('CSQDetail')
    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.CSQDetailGrid.saveState('CSQDetail') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.CSQDetailGrid.saveState('CSQDetail') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.CSQDetailGrid.saveState('CSQDetail') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.CSQDetailGrid.saveState('CSQDetail') }
    // Air
    if (this.type === 'A') {
      this.aggrid.columnDefs = [
        {
          headerName: 'MAWB', field: 'mawb', minWidth: 175, cellRenderer: 'agGroupCellRenderer',
        },
        {
          headerName: 'Status', field: 'latestStatusCode', minWidth: 75,
        },
        {
          headerName: 'Part', field: 'masterPart', minWidth: 75,
        },
        {
          headerName: 'In-Bond #', field: 'inBondNumber', minWidth: 125,
        },
        {
          headerName: 'Boarded/Manifest Qty',
          field: 'masterManifestQty',
          minWidth: 125,
          cellRenderer: params => `<span>${params.data.masterBoardedQty}/${params.value}</span>`,
        },
        {
          headerName: 'Carrier', field: 'importingCarrier', minWidth: 95,
        },
        {
          headerName: 'Flight #', field: 'flightNumber', minWidth: 95,
        },
        {
          headerName: 'ETA', field: 'doa', minWidth: 140,
        },
        {
          headerName: 'Port of Unlading', field: 'actualPOU', minWidth: 150,
        },
      ]
      this.aggrid.masterDetail = true
      this.aggrid.detailCellRendererParams = {
        detailGridOptions: {
          columnDefs: [
            {
              headerName: 'Code', field: 'dispositionCode', minWidth: 115, maxWidth: 115,
            },
            { headerName: 'Disposition', field: 'disposition', minWidth: 175 },
            {
              headerName: 'Date', field: 'dispositionDate', minWidth: 150, maxWidth: 150,
            },
            {
              headerName: 'In-Bond/Entry #', field: 'identifier', minWidth: 155, maxWidth: 155,
            },
          ],
          defaultColDef: {
            flex: 1,
            sortable: true,
            resizable: true,
          },
        },
        getDetailRowData(params) {
          params.successCallback(params.data.dispositions)
        },
      }
    } else {
      this.aggrid.columnDefs = [
        {
          headerName: 'Code', field: 'dispositionCode', minWidth: 115, maxWidth: 115,
        },
        { headerName: 'Disposition', field: 'disposition', minWidth: 185 },
        { headerName: 'Date', field: 'dispositionDate', minWidth: 180 },
        { headerName: 'Qty', field: 'qty', minWidth: 150 },
        { headerName: 'Sequence', field: 'sequence', minWidth: 150 },
      ]
    }

    this.LoadAll()
  },
  methods: {
    LoadAll() {
      return Promise.all([this.LoadingStart(), this.LoadData()])
        .then(() => {
          this.LoadingEnd()
          let isfonfile = ''
          if (this.ISFIndicator === 'Y') isfonfile = '<small>&</small> <small style="color:green">ISF On File</small>'
          else isfonfile = '<small>&</small> <small style="color:red">ISF <strong>NOT</strong> On File</small>'

          if (this.InQueue !== '' && this.InQueue !== null) {
            this.header = `<h4 class="card-title">Bill #: ${this.Master} - Pending Status</h4>`
          } else {
            this.header = ''
            if (this.ErrorMsgId !== '' && this.ErrorMsgId !== null) {
              this.header = `<h4 class="card-title">Bill #: ${this.Master}</h4>`
            } else {
              this.header = ''
              if (this.type === 'A') {
                this.header = `<h4 class="card-title">${this.BillType === 'Regular BOL' ? 'Direct/Regular Bill' : 'Master'} #: ${this.Master}</h4>`
              } else if (this.type === 'O') {
                this.header = ''
                if (this.TotalHouseQty === this.ManifestQty || this.BillType === 'Regular BOL') {
                  this.header = `<h4 class="card-title">${this.BillType === 'Regular BOL' ? 'Direct/Regular Bill' : 'Master'} #: ${this.Master} <u><small style="color:green">No discrepancy found</small> ${isfonfile}</u></h4>`
                } else {
                  this.header = `<h4 class="card-title">${this.BillType === 'Regular BOL' ? 'Direct/Regular Bill' : 'Master'} #: ${this.Master} <u><small style="color:red">Discrepancy found</small> ${isfonfile}</u></h4>`
                }
              }
            }
          }
        })
    },
    Refresh() {
      this.LoadAll()
      this.index += 1
    },
    LoadData() {
      if (this.type === 'A') {
        return this.LoadAirConveyance().then(() => {
          this.LoadAirMaster()
        })
      }
      if (this.type === 'O') return this.LoadORTMaster()
      return true
    },
    LoadAirConveyance() {
      return axios.get(`/abi/query/cargostatus/${this.queryid}/air/bill`)
        .then(resp => {
          const response = resp.data
          if (response.length === 0) {
            this.InQueue = 'In Queue - Pending'
          } else {
            this.InQueue = ''
            const respdata = response[0]
            this.POE = respdata.poe
            this.MOT = 'Air'
            this.EntryFilerCode = respdata.entryFilerCode
            this.EntryNumber = respdata.entryNumber
            this.EntryType = respdata.entryType
            this.Importer = respdata.importer
            this.BrokerRefNumber = respdata.brokerRefNumber
            this.CarrierCode = respdata.carrierCode
            this.CarrierCodeIATA = respdata.carrierCodeIATA
            try {
              // eslint-disable-next-line prefer-destructuring
              this.ConveyanceName = respdata.conveyanceName.split(' ')[0]
            } catch (e) {
              this.ConveyanceName = ''
            }
            this.FlightVoyage = respdata.flightVoyage
            this.DOA = respdata.doa
            this.TripNumber = respdata.tripNumber
            this.FIRMS = respdata.firms
            this.QueryBy = respdata.queryBy
            this.QueryDate = respdata.queryDate
            this.LastUpdated = respdata.lastUpdated
            this.ErrorMsgId = respdata.errorMsgId
            this.ErrorMsg = respdata.errorMsg
          }
        })
    },
    LoadAirMaster() {
      this.masterBills = []
      return axios.get(`/abi/query/cargostatus/${this.queryid}/A/true/disposition`)
        .then(rowData => {
          this.aggrid.rowData = rowData.data
          this.aggrid.rowData.forEach(e => {
            this.masterBills.push({
              id: e.billId,
              CarrierCode: this.CheckNull(e.importingCarrier) ? e.importingCarrier : '',
              CarrierCodeIATA: this.CheckNull(e.carrierCodeIATA) ? e.carrierCodeIATA : '',
              FlightVoyage: this.CheckNull(e.flightNumber) ? e.flightNumber : '',
              DOA: this.CheckNull(e.doa) ? e.doa : '',
              FIRMS: this.CheckNull(e.FIRMS) ? this.FIRMS : '',
              ErrorMsgId: this.CheckNull(e.ErrorMsgId) ? this.ErrorMsgId : '',
              ErrorMsg: this.CheckNull(e.ErrorMsg) ? this.ErrorMsg : '',
              InBondNumber: this.CheckNull(e.inBondNumber) ? e.inBondNumber : '',
              InBondStatus: this.CheckNull(e.inBoundStatus) ? e.inBoundStatus : '',
              InBondOriginatingPort: this.CheckNull(e.inBondOriginatingPort) ? e.inBondOriginatingPort : '',
              MOT: 'Air',
              HBL: this.CheckNull(e.hawb) ? e.hawb : '',
              HBLPart: this.CheckNull(e.housePart) ? e.housePart : '',
              HouseQty: this.CheckNull(e.houseManifestQty) ? e.houseManifestQty : 0,
              HouseBoarded: this.CheckNull(e.houseBoardedQty) ? e.houseBoardedQty : 0,
              // eslint-disable-next-line no-nested-ternary
              MBL: e.mawb === '' || e.mawb === null ? 'N/A' : this.CheckNull(e.mawb) ? e.mawb : '',
              MBLPart: this.CheckNull(e.masterPart) ? e.masterPart : '',
              MasterQty: this.CheckNull(e.masterManifestQty) ? e.masterManifestQty : 0,
              MasterBoarded: this.CheckNull(e.masterBoardedQty) ? e.masterBoardedQty : 0,
              ManifestedPOU: this.CheckNull(e.manifestedPOU) ? e.manifestedPOU : '',
              ActualPOU: this.CheckNull(e.actualPOU) ? e.actualPOU : '',
              Disposition: this.CheckNull(e.dispositions) ? e.dispositions : [],
            })
          })
        }).then(() => {
          if ((this.ErrorMsgId === '' || this.ErrorMsgId === null) && (this.InQueue === '' || this.InQueue === null)) {
            this.$refs.CSQDetailGrid.ResetColumns()
            this.LoadAirHouse()
          }
        })
    },
    LoadAirHouse() {
      this.housebills = []
      axios.get(`/abi/query/cargostatus/${this.queryid}/A/false/disposition`)
        .then(resp => {
          const response = resp.data
          response.forEach(e => {
            this.houseBills.push({
              id: e.billId,
              CarrierCode: this.CheckNull(e.importingCarrier) ? e.importingCarrier : '',
              CarrierCodeIATA: this.CheckNull(e.carrierCodeIATA) ? e.carrierCodeIATA : '',
              FlightVoyage: this.CheckNull(e.flightNumber) ? e.flightNumber : '',
              DOA: this.CheckNull(e.doa) ? e.doa : '',
              FIRMS: this.CheckNull(e.FIRMS) ? this.FIRMS : '',
              ErrorMsgId: this.CheckNull(e.ErrorMsgId) ? this.ErrorMsgId : '',
              ErrorMsg: this.CheckNull(e.ErrorMsg) ? this.ErrorMsg : '',
              InBondNumber: this.CheckNull(e.inBondNumber) ? e.inBondNumber : '',
              InBondStatus: this.CheckNull(e.inBoundStatus) ? e.inBoundStatus : '',
              MOT: 'Air',
              HBL: this.CheckNull(e.hawb) ? e.hawb : '',
              HBLPart: this.CheckNull(e.housePart) ? e.housePart : '',
              HouseQty: this.CheckNull(e.houseManifestQty) ? e.houseManifestQty : 0,
              HouseBoarded: this.CheckNull(e.houseBoardedQty) ? e.houseBoardedQty : 0,
              MBL: this.CheckNull(e.mawb) ? e.mawb : '',
              MBLPart: this.CheckNull(e.masterPart) ? e.masterPart : '',
              MasterQty: this.CheckNull(e.masterManifestQty) ? e.masterManifestQty : 0,
              MasterBoarded: this.CheckNull(e.masterBoardedQty) ? e.masterBoardedQty : 0,
              ManifestedPOU: this.CheckNull(e.manifestedPOU) ? e.manifestedPOU : '',
              ActualPOU: this.CheckNull(e.actualPOU) ? e.actualPOU : '',
              Disposition: this.CheckNull(e.dispositions) ? e.dispositions : [],
            })
          })
        })
    },

    LoadORTMaster() {
      return axios.get(`/abi/query/cargostatus/${this.queryid}/O/true/disposition`)
        .then(resp => {
          const response = resp.data
          if (response.length === 0) {
            this.InQueue = 'In Queue - Pending'
          } else {
            this.InQueue = ''
            const respdata = response[0]
            this.ConveyanceName = this.CheckNull(respdata.conveyanceName) ? respdata.conveyanceName : ''
            this.FlightVoyage = this.CheckNull(respdata.voyage) ? respdata.voyage : ''
            this.DOA = this.CheckNull(respdata.doa) ? respdata.doa : ''
            this.TripNumber = this.CheckNull(respdata.tripNumber) ? respdata.tripNumber : ''
            this.FIRMS = this.CheckNull(respdata.firms) ? respdata.firms : ''
            this.BillType = this.CheckNull(respdata.billType) ? respdata.billType : ''
            this.BillTypeCode = this.CheckNull(respdata.billTypeCode) ? respdata.billTypeCode : ''
            this.InBondNumber = this.CheckNull(respdata.inBondNumber) ? respdata.inBondNumber : ''
            this.ISFIndicator = this.CheckNull(respdata.isfIndicator) ? respdata.isfIndicator : ''
            this.ManifestQty = this.CheckNull(respdata.manifestQty) ? respdata.manifestQty : ''
            this.MOT = this.CheckNull(respdata.mot) ? respdata.mot : ''
            this.Unit = this.CheckNull(respdata.unit) ? respdata.unit : ''
            this.ManifestedPOU = this.CheckNull(respdata.manifestedPOU) ? respdata.manifestedPOU : ''
            this.ActualPOU = this.CheckNull(respdata.actualPOU) ? respdata.actualPOU : ''
            this.InBondOriginatingPort = this.CheckNull(respdata.inBondOriginatingPort) ? respdata.inBondOriginatingPort : ''
            this.ManifestedInBondDestPort = this.CheckNull(respdata.manifestedInBondDestPort) ? respdata.manifestedInBondDestPort : ''
            this.VesselDepartPort = this.CheckNull(respdata.vesselDepartPort) ? respdata.vesselDepartPort : ''
            this.VesselDepartDate = this.CheckNull(respdata.vesselDepartDate) ? respdata.vesselDepartDate : ''
            this.TotalHouseQty = this.CheckNull(respdata.totalHouseQty) ? respdata.totalHouseQty : ''
            this.TotalHouseUnit = this.CheckNull(respdata.totalHouseUnit) ? respdata.totalHouseUnit : ''
            this.QueryBy = respdata.queryBy
            this.QueryDate = respdata.queryDate
            this.LastUpdated = respdata.lastUpdated
            this.ErrorMsgId = this.CheckNull(respdata.errorMsgId) ? respdata.errorMsgId : ''
            this.ErrorMsg = this.CheckNull(respdata.errorMsg) ? respdata.errorMsg : ''
            // aggrid
            this.aggrid.rowData = this.CheckNull(resp.data[0].dispositions) ? resp.data[0].dispositions : []
          }
        }).then(() => {
          if ((this.ErrorMsgId === '' || this.ErrorMsgId === null) && (this.InQueue === '' || this.InQueue === null)) {
            this.$refs.CSQDetailGrid.ResetColumns()
            this.LoadORTHouse()
          }
        })
    },
    LoadORTHouse() {
      return axios.get(`/abi/query/cargostatus/${this.queryid}/O/false/disposition`)
        .then(resp => {
          const response = resp.data
          response.forEach(e => {
            this.houseBills.push({
              id: e.houseId,
              ConveyanceName: e.conveyanceName,
              FlightVoyage: this.CheckNull(e.voyage) ? e.voyage : '',
              DOA: this.CheckNull(e.doa) ? e.doa : '',
              FIRMS: this.CheckNull(e.firms) ? e.firms : '',
              BillType: this.CheckNull(e.billType) ? e.billType : '',
              BillTypeCode: this.CheckNull(e.billTypeCode) ? e.billTypeCode : '',
              InBondNumber: this.CheckNull(e.inBondNumber) ? e.inBondNumber : '',
              ISFIndicator: this.CheckNull(e.isfIndicator) ? e.isfIndicator : 'N',
              SCAC: this.CheckNull(e.scac) ? e.scac : '',
              HBL: this.CheckNull(e.hbl) ? e.hbl : '',
              HBLQty: this.CheckNull(e.manifestQty) ? e.manifestQty : 0,
              HBLUnit: this.CheckNull(e.unit) ? e.unit : '',
              MBLQty: this.CheckNull(e.masterQty) ? e.masterQty : 0,
              MBLUnit: this.CheckNull(e.masterUnit) ? e.masterUnit : '',
              MOT: e.mot,
              ManifestedPOU: this.CheckNull(this.ManifestedPOU) ? this.ManifestedPOU : '',
              ActualPOU: this.CheckNull(this.ActualPOU) ? this.ActualPOU : '',
              InBondOriginatingPort: this.CheckNull(this.InBondOriginatingPort) ? this.InBondOriginatingPort : '',
              ManifestedInBondDestPort: this.CheckNull(this.ManifestedInBondDestPort) ? this.ManifestedInBondDestPort : '',
              VesselDepartPort: this.CheckNull(this.VesselDepartPort) ? this.VesselDepartPort : '',
              VesselDepartDate: this.CheckNull(this.VesselDepartDate) ? this.VesselDepartDate : '',
              ErrorMsgId: this.CheckNull(e.errorMsgId) ? this.ErrorMsgId : '',
              ErrorMsg: this.CheckNull(e.errorMsg) ? this.ErrorMsg : '',
              Disposition: this.CheckNull(e.dispositions) ? e.dispositions : [],
            })
          })
        })
    },
    RequestUpdate() {
      this.LoadingStart()
      axios.post(`/abi/query/cargostatus/${this.queryid}/update`)
        .then(() => {
          this.LoadingEnd()
          this.DisplaySuccess('Update has been requested.').then(() => {
            this.Refresh()
          })
        })
        .catch(e => {
          this.LoadingEnd()
          this.DisplayError('Cannot request for update.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
        })
    },
    Subscribe() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, subscribe!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.put(`/abi/query/cargostatus/${this.queryid}/subscribe`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Success!  You will receive updates automatically.')
              .then(() => {
                // refresh
                this.LoadData()
                this.subscriptionstatus = 'true'
                this.$router.push(`/abi/query/cargostatus/detail/${this.queryid}/${this.type}/${this.Master}/true`)
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot subscribe this query.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    LoadPDF() {
      const dd = {
        pageOrientation: 'portrait',
        pageSize: 'LETTER',
        content: [],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 10],
          },
          subheader: {
            fontSize: 7.5,
            margin: [0, 5, 0, 5],
          },
          table: {
            margin: [2, 5, 2, 5],
          },
          tableHeader: {
            bold: true,
            fontSize: 7.5,
            color: 'black',
          },
          tbtitle: {
            bold: true,
            italics: true,
            fontSize: 7.5,
            color: 'black',
            alignment: 'center',
          },
        },
        defaultStyle: {
          fontSize: 7.5,
          alignment: 'left',
        },
      }
      let x = 0
      if (this.type === 'A') {
        this.masterBills.forEach(bill => {
          if (x === 1) x = 0
          // Shipment Detail Table
          if (this.ErrorMsgId === '' || this.ErrorMsgId === null) {
            const shipment = {
              style: 'table',
              color: '#444',
              layout: this.TableStyleStripeLine,
              table: {
                widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                body: [
                  [{ text: 'AWB:', style: 'tbtitle', alignment: 'left' }, { text: `${bill.MBL}` },
                    { text: 'Queried Date:', style: 'tbtitle', alignment: 'left' }, { text: `${this.QueryDate}` },
                    { text: 'Last Update Date:', style: 'tbtitle', alignment: 'left' }, { text: `${this.LastUpdated}` },
                    {}, {}],
                  [{ text: 'Carrier:', style: 'tbtitle', alignment: 'left' }, { text: `${bill.CarrierCodeIATA} (${bill.CarrierCode})` },
                    { text: 'Flight:', style: 'tbtitle', alignment: 'left' }, { text: `${bill.FlightVoyage}` },
                    { text: 'ETA:', style: 'tbtitle', alignment: 'left' }, { text: `${bill.DOA}` },
                    { text: 'FIRMS:', style: 'tbtitle', alignment: 'left' }, { text: `${this.FIRMS}` }],
                ],
              },
            }
            dd.content.push(shipment)

            const shipment1 = {
              style: 'table',
              color: '#444',
              layout: this.TableStyleStripeLine,
              table: {
                widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                body: [
                  [{ text: 'InBond No.:', style: 'tbtitle' }, { text: `${bill.InBondNumber}` },
                    { text: 'Master Manifested Quantity:', style: 'tbtitle' }, { text: `${bill.MasterQty}` },
                    { text: 'Master Boarded Quantity:', style: 'tbtitle' }, { text: `${bill.MasterBoarded}` },
                    { text: 'Arrival Date:', style: 'tbtitle' }, { text: `${bill.DOA}` },
                    { text: 'Part Indicator:', style: 'tbtitle' }, { text: `${bill.MBLPart}` }],
                  [{ text: 'House Airway Bill No:', style: 'tbtitle' }, { text: '-' },
                    { text: 'House Manifest Quantity :', style: 'tbtitle' }, { text: '-' },
                    { text: 'House Boarded Quantity:', style: 'tbtitle' }, { text: '-' },
                    { text: 'House Part Indicator:', style: 'tbtitle' }, { text: '-' },
                    { text: 'Flight Number:', style: 'tbtitle' }, { text: `${bill.FlightVoyage}` }],
                ],
              },
            }
            dd.content.push(shipment1)
            // Disposition Table
            if (bill.Disposition.length !== 0) {
              const disposition = {
                style: 'table',
                color: '#444',
                layout: this.TableStyleStripeLine,
                table: {
                  widths: [126, 240, 140],
                  body: [
                    [{ text: 'Disposition Code', style: 'tableHeader', alignment: 'center' }, { text: 'Disposition', style: 'tableHeader', alignment: 'center' }, { text: 'Disposition Date', style: 'tableHeader', alignment: 'center' }],
                  ],
                },
              }
              bill.Disposition.forEach(d => {
                disposition.table.body.push([`${d.dispositionCode}`, `${d.disposition}`, `${d.dispositionDate}`])
              })
              dd.content.push(disposition)
            } else {
              dd.content.push({ text: 'No Disposition', style: 'subheader', alignment: 'center' })
            }
          } else {
            const error = {
              style: 'table',
              color: '#444',
              table: {
                widths: ['auto', 'auto', 'auto', 'auto'],
                body: [
                  [{ text: 'Error Msg ID:', style: 'tbtitle' }, { text: `${bill.ErrorMsgId}` }, { text: 'Error Msg:', style: 'tbtitle' }, { text: `${bill.ErrorMsg}` }],
                ],
              },
            }
            dd.content.push(error)
          }

          x += 1
        })

        if (this.BillType !== 'Regular BOL') {
          this.houseBills.forEach(bill => {
            // Shipment Detail Table
            if (this.ErrorMsgId === '' || this.ErrorMsgId === null) {
              const shipment1 = {
                style: 'table',
                color: '#444',
                layout: this.TableStyleStripeLine,
                table: {
                  widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                  body: [
                    [{ text: 'InBond No.:', style: 'tbtitle' }, { text: `${bill.InBondNumber}` },
                      { text: 'Master Manifested Quantity:', style: 'tbtitle' }, { text: `${bill.MasterQty}` },
                      { text: 'Master Boarded Quantity:', style: 'tbtitle' }, { text: `${bill.MasterBoarded}` },
                      { text: 'Arrival Date:', style: 'tbtitle' }, { text: `${bill.DOA}` },
                      { text: 'Part Indicator:', style: 'tbtitle' }, { text: `${bill.MBLPart}` }],
                    [{ text: 'House Airway Bill No:', style: 'tbtitle' }, { text: `${bill.HBL}` },
                      { text: 'House Manifest Quantity :', style: 'tbtitle' }, { text: `${bill.HouseQty}` },
                      { text: 'House Boarded Quantity:', style: 'tbtitle' }, { text: `${bill.HouseBoarded}` },
                      { text: 'House Part Indicator:', style: 'tbtitle' }, { text: `${bill.HBLPart}` },
                      { text: 'Flight Number:', style: 'tbtitle' }, { text: `${bill.FlightVoyage}` }],
                  ],
                },
              }
              dd.content.push(shipment1)
              // Disposition Table
              if (bill.Disposition.length !== 0) {
                const disposition = {
                  style: 'table',
                  color: '#444',
                  layout: this.TableStyleStripeLine,
                  table: {
                    widths: [126, 240, 140],
                    body: [
                      [{ text: 'Disposition Code', style: 'tableHeader', alignment: 'center' }, { text: 'Disposition', style: 'tableHeader', alignment: 'center' }, { text: 'Disposition Date', style: 'tableHeader', alignment: 'center' }],
                    ],
                  },
                }
                bill.Disposition.forEach(d => {
                  disposition.table.body.push([`${d.dispositionCode}`, `${d.disposition}`, `${d.dispositionDate}`])
                })
                dd.content.push(disposition)
              } else {
                dd.content.push({ text: 'No Disposition', style: 'subheader', alignment: 'center' })
              }
            } else {
              const error = {
                style: 'table',
                color: '#444',
                table: {
                  widths: ['auto', 'auto', 'auto', 'auto'],
                  body: [
                    [{ text: 'Error Msg ID:', style: 'tbtitle' }, { text: `${bill.ErrorMsgId}` }, { text: 'Error Msg:', style: 'tbtitle' }, { text: `${bill.ErrorMsg}` }],
                  ],
                },
              }
              dd.content.push(error)
            }
          })
        }
      } else if (this.type === 'O') {
        if (this.ErrorMsgId === '' || this.ErrorMsgId === null) {
          const shipment = {
            styles: 'table',
            color: '#444',
            layout: this.TableStyleStripeLine,
            table: {
              widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
              body: [
                [{ text: 'MBL:', style: 'tbtitle' }, { text: `${this.Master}` },
                  { text: 'HBL:', style: 'tbtitle' }, { text: '' },
                  { text: 'Queried Date:', style: 'tbtitle', alignment: 'left' }, { text: `${this.QueryDate}` },
                  { text: 'Last Update Date:', style: 'tbtitle', alignment: 'left' }, { text: `${this.LastUpdated}` },
                ],
                [{ text: 'Vessel:', style: 'tbtitle' }, { text: `${this.ConveyanceName}` },
                  { text: 'Voyage:', style: 'tbtitle' }, { text: `${this.FlightVoyage}` },
                  { text: 'ETA:', style: 'tbtitle', alignment: 'left' }, { text: `${this.DOA}` },
                  { text: 'FIRMS:', style: 'tbtitle', alignment: 'left' }, { text: `${this.FIRMS}` },
                ],
                [{ text: 'Quantity:', style: 'tbtitle' }, { text: `${this.ManifestQty}` },
                  { text: 'Unit:', style: 'tbtitle' }, { text: `${this.Unit}` },
                  { text: 'SCAC:', style: 'tbtitle', alignment: 'left' }, { text: `${this.Master.substring(0, 4)}` },
                  { text: 'Inbond Number:', style: 'tbtitle', alignment: 'left' }, { text: `${this.InBondNumber}` },
                ],
                [{ text: 'BOL Type:', style: 'tbtitle' }, { text: `${this.BillType}` },
                  { text: 'ISF on File:', style: 'tbtitle' }, { text: `${this.ISFIndicator}` },
                  { text: 'MOT:', style: 'tbtitle', alignment: 'left' }, { text: `${this.MOT}` },
                  { }, { },
                ],
              ],
            },
          }
          dd.content.push(shipment)
          // Disposition Table
          if (this.aggrid.rowData.length !== 0) {
            const disposition = {
              style: 'table',
              color: '#444',
              layout: this.TableStyleStripeLine,
              table: {
                widths: [118, 174, 164, 40],
                body: [
                  [{ text: 'Disposition Code', style: 'tableHeader', alignment: 'center' }, { text: 'Disposition', style: 'tableHeader', alignment: 'center' }, { text: 'Disposition Date', style: 'tableHeader', alignment: 'center' }, { text: 'Qty ', style: 'tableHeader', alignment: 'center' }],
                ],
              },
            }
            this.aggrid.rowData.forEach(d => {
              disposition.table.body.push([`${d.dispositionCode}`, `${d.disposition}`, `${d.dispositionDate}`, `${d.qty}`])
            })
            dd.content.push(disposition)
          } else {
            dd.content.push({ text: 'No Disposition', style: 'subheader', alignment: 'center' })
          }
        } else {
          const error = {
            style: 'table',
            color: '#444',
            table: {
              widths: ['auto', 'auto', 'auto', 'auto'],
              body: [
                [{ text: 'Error Msg ID:', style: 'tbtitle' }, { text: `${this.ErrorMsgId}` }, { text: 'Error Msg:', style: 'tbtitle' }, { text: `${this.ErrorMsg}` }],
              ],
            },
          }
          dd.content.push(error)
        }
        if (this.BillType !== 'Regular BOL') {
          this.houseBills.forEach(bill => {
            // Shipment Detail Table
            if (this.ErrorMsgId === '' || this.ErrorMsgId === null) {
              const shipment = {
                styles: 'table',
                color: '#444',
                layout: this.TableStyleStripeLine,
                table: {
                  widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                  body: [
                    [{ text: 'MBL:', style: 'tbtitle' }, { text: `${this.Master}` },
                      { text: 'HBL:', style: 'tbtitle' }, { text: `${bill.HBL}` },
                      { text: 'Queried Date:', style: 'tbtitle', alignment: 'left' }, { text: `${this.QueryDate}` },
                      { text: 'Last Update Date:', style: 'tbtitle', alignment: 'left' }, { text: `${this.LastUpdated}` },
                    ],
                    [{ text: 'Vessel:', style: 'tbtitle' }, { text: `${this.ConveyanceName}` },
                      { text: 'Voyage:', style: 'tbtitle' }, { text: `${this.FlightVoyage}` },
                      { text: 'ETA:', style: 'tbtitle', alignment: 'left' }, { text: `${bill.DOA}` },
                      { text: 'FIRMS:', style: 'tbtitle', alignment: 'left' }, { text: `${bill.FIRMS}` },
                    ],
                    [{ text: 'Quantity:', style: 'tbtitle' }, { text: `${bill.HBLQty}` },
                      { text: 'Unit:', style: 'tbtitle' }, { text: `${bill.HBLUnit}` },
                      { text: 'SCAC:', style: 'tbtitle', alignment: 'left' }, { text: `${bill.SCAC}` },
                      { text: 'Inbond Number:', style: 'tbtitle', alignment: 'left' }, { text: `${bill.InBondNumber}` },
                    ],
                    [{ text: 'BOL Type:', style: 'tbtitle' }, { text: `${bill.BillType}` },
                      { text: 'ISF on File:', style: 'tbtitle' }, { text: `${bill.ISFIndicator}` },
                      { text: 'MOT:', style: 'tbtitle', alignment: 'left' }, { text: `${this.MOT}` },
                      { }, { },
                    ],
                  ],
                },
              }
              dd.content.push(shipment)
              // Disposition Table
              if (bill.Disposition.length !== 0) {
                const disposition = {
                  style: 'table',
                  color: '#444',
                  layout: this.TableStyleStripeLine,
                  table: {
                    widths: [118, 174, 164, 40],
                    body: [
                      [{ text: 'Disposition Code', style: 'tableHeader', alignment: 'center' }, { text: 'Disposition', style: 'tableHeader', alignment: 'center' }, { text: 'Disposition Date', style: 'tableHeader', alignment: 'center' }, { text: 'Qty ', style: 'tableHeader', alignment: 'center' }],
                    ],
                  },
                }
                bill.Disposition.forEach(d => {
                  disposition.table.body.push([`${d.dispositionCode}`, `${d.disposition}`, `${d.dispositionDate}`, `${d.qty}`])
                })
                dd.content.push(disposition)
              } else {
                dd.content.push({ text: 'No Disposition', style: 'subheader', alignment: 'center' })
              }
            } else {
              const error = {
                style: 'table',
                color: '#444',
                table: {
                  widths: ['auto', 'auto', 'auto', 'auto'],
                  body: [
                    [{ text: 'Error Msg ID:', style: 'tbtitle' }, { text: `${this.ErrorMsgId}` }, { text: 'Error Msg:', style: 'tbtitle' }, { text: `${this.ErrorMsg}` }],
                  ],
                },
              }
              dd.content.push(error)
            }
          })
        }
      }
      return dd
    },
    Print() {
      const doc = this.LoadPDF()
      const win = window.open('', '_blank')
      pdfMake.createPdf(doc).open({}, win)
    },
  },
}
</script>
